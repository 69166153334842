import GLightbox from 'glightbox';

(() => {
    const gallery = new GLightbox({
        selector: '[data-gallery]',
        skin: 'sdl',
        svg: {
            close: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M20.8086032,2.15982613 C21.1021344,1.94186341 21.518807,1.96591581 21.7851717,2.23208416 C22.0781729,2.52486935 22.078348,2.99974306 21.7855628,3.29274426 L21.7855628,3.29274426 L13.0639697,12.0196098 L21.7855628,20.7471928 C22.078348,21.040194 22.0781729,21.5150677 21.7851717,21.8078529 C21.518807,22.0740213 21.1021344,22.0980737 20.8086032,21.880111 L20.7245116,21.8074618 L12.0039697,13.0806098 L3.28449529,21.8074618 L3.20040364,21.880111 C2.9068725,22.0980737 2.49019992,22.0740213 2.22383519,21.8078529 C1.93083398,21.5150677 1.93065887,21.040194 2.22344406,20.7471928 L2.22344406,20.7471928 L10.9439697,12.0196098 L2.22344406,3.29274426 C1.93065887,2.99974306 1.93083398,2.52486935 2.22383519,2.23208416 C2.49019992,1.96591581 2.9068725,1.94186341 3.20040364,2.15982613 L3.28449529,2.23247529 L12.0039697,10.9586098 L20.7245116,2.23247529 Z"/></svg>',
            prev: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M20.7343997,7.20340672 C21.0369343,6.9196125 21.5124245,6.93461618 21.7964369,7.23691839 C22.05463,7.51173857 22.0656928,7.92938754 21.8382717,8.21627127 L21.7628995,8.29813984 L12.8478053,16.6609927 C12.4000257,17.0810349 11.7203768,17.1107014 11.2398501,16.7502163 L11.1326902,16.6601017 L2.23653106,8.29760525 C1.93429195,8.01349685 1.91977114,7.53835648 2.20409793,7.23634952 C2.46257683,6.96179775 2.87905475,6.92484705 3.17971854,7.1338526 L3.26616944,7.20394131 L11.9905252,15.4052397 L20.7343997,7.20340672 Z" transform="rotate(90 12 12)"/></svg>',
            next: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M20.8437948,7.13279801 C21.1463294,6.84900379 21.6218196,6.86400747 21.905832,7.16630967 C22.1640251,7.44112986 22.1750879,7.85877882 21.9476668,8.14566256 L21.8722946,8.22753112 L12.9572004,16.590384 C12.5094207,17.0104262 11.8297719,17.0400927 11.3492452,16.6796076 L11.2420853,16.589493 L2.34592614,8.22699653 C2.04368703,7.94288814 2.02916623,7.46774777 2.31349302,7.16574081 C2.57197192,6.89118903 2.98844984,6.85423833 3.28911363,7.06324389 L3.37556453,7.1333326 L12.0999248,15.334631 L20.8437948,7.13279801 Z" transform="rotate(-90 12.11 11.93)"/></svg>'
        }
    });
})();